import React, { useState } from "react";
import { Button, TextField} from '@mui/material';
import  PageDataService from '../services/page.service'
import { useNavigate } from 'react-router-dom';
    

const AddPage = () => {
    const [page, setPage] = useState ({pageTitle: "", pageText: ""});

    const handleChange = (e) => {
        setPage(prevState => ({...prevState, [e.target.name]: [e.target.value]}));
    }

    const savePage = (pageData) => {
       PageDataService.addPage(pageData)
    }

    const navigate = useNavigate();

    return (
        <div>
            <TextField name='pageTitle' type='string' required={true} label='Enter the page title' onChange={handleChange} /> 
            <TextField name='pageText' minRows={2} fullWidth multiline type='string' required={true} label='Enter the page text' onChange={handleChange} /> 
            <Button onClick={() => savePage(page)} > Save Page </Button>
            <Button onClick={() => navigate("/")} > Cancel </Button>
        </div>
    )
};

export default AddPage;