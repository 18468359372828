import AddPage from './components/AddPage';
import ViewPages from './components/ViewPages';
import authService from './services/auth.service';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import { Button } from '@mui/material';



function App() {
  
  const [user] = useAuthState(authService.auth);
  
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" replace />
    }
    return children
  }

  const navigate = useNavigate();
    useEffect(() => {
     
    },[user]);

    return (
    
      <div className="App">
        <>
        {(user ? <Button onClick={authService.logOut} >Sign Out</Button> : <Button onClick={authService.logIn} >Sign in</Button> )}
        {(user ? <Button onClick={()=> navigate("/addpage")}>Add Page</Button> : <></>)}
        <Routes>
          <Route path='/addpage' element={
            <ProtectedRoute user={user}>
              <AddPage />
            </ProtectedRoute> 
          }
          />
          <Route path='/' element={<ViewPages />} />
        </Routes>
        </>
      </div>
    
  );
}

export default App;
