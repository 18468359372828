import { db } from '../firebase-config';
import { collection, getDocs, addDoc } from 'firebase/firestore';
        //TODO: getDoc, updateDoc, deleteDoc, doc

const pageCollectionRef = collection(db, "pages");
class pageDataService {
    addPage = (newPage) => {
        let page = { pageTitle: newPage.pageTitle[0], pageText: newPage.pageText[0] }
        return addDoc(pageCollectionRef, page)
    }
    getPages = () => {
        
        return getDocs(pageCollectionRef)
    }
}

export default new pageDataService();