import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'
import { auth, db } from '../firebase-config';

class authService {

    constructor(auth) {
        this.auth = auth;
     }

    logIn = async () => {
        const googleProvier = new GoogleAuthProvider();
        try {
            
            const res = await signInWithPopup(auth, googleProvier);
            const user = res.user;
            const qry = query(collection(db, "users"), where ("uid", "==", user.uid));
            const docs = await getDocs(qry);
            if (docs.docs.length === 0){
                await addDoc(collection(db, "users"), {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                })
            }
        }
        catch (err){
            alert("An Error Occured");
        }
    }
    logOut = () => {
        signOut(auth)
    }
    
}

export default new authService(auth);