import React, { useEffect, useState } from "react";
import  PageDataService from '../services/page.service'


const ViewPages = () => {
    const [pages, setPages] = useState(null);
    useEffect(() => {
        getPages();

    },[])

    const getPages = async () => { await PageDataService.getPages()
        .then((snapshot) => {
            const data = snapshot.docs
                .map((doc) => ({
                    ...doc.data(), id: doc.id
                }));
            setPages(data);

        })
    
    }

    if (pages != null) {
    return (
        <> 
        <div>
            { pages.map(page => {
            return (
                <div key={page.id}>
                    <div>{page.pageTitle}</div>
                    <div>{page.pageText}</div>
                </div>
            )
        })}
        
            
        </div>
        
        </>
    )
    }
    else return (<></>)
}

export default ViewPages